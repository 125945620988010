import React, { useState } from 'react';
import logo from './Logomarca Cooperval-04.png';
import styled from "styled-components";
import { ImExit } from "react-icons/im";
import Cotacao from './Cotacao.js';
import { toast } from 'react-toastify';


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Ocupa toda a altura da tela */
  overflow: hidden; /* Evita rolagem extra */
  gap: 25px;

  &.name-apresentacao{
    position: absolute;
    top: 0;
    left: 0;
    padding: 1vw;
  }

  @media (max-width: 480px) {
    overflow-y: scroll;
  }
  @media (max-width: 400px) {
    overflow-y: scroll;
  }
`;
const H3 = styled.p`
  color:  #076633;
  border: 1px solid #ced2d4; 
  border-radius: 15px;
  &.name-apresentacao{
    position: absolute;
    top: 0;
    left: 0;
    padding: 1vw;
    margin: 5px;
  }

`;
const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  @media (max-width: 480px) {
    display: none;
  }
  
  @media (max-width: 400px) {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 

  @media (max-width: 480px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const Button2 = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  top: 2;
  left: 0;
  font-size: 24px;
  cursor: pointer;
  color:  #076633;
  border: 1px solid #ced2d4; 
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.3s, background 0.3s, border 0.3s;

  &:hover {
    background-color: #076633;
    color: white;
  }


`;
const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  color: black;
  font-size: 22px;

  &.button-exit{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1vw;
  }



`;
const InputArea1 = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;


  
`;
const Div = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;

  
`;
const Select = styled.select`
  width: 350px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 37px;

  @media (max-width: 480px) {
    width: 250px;

  @media (max-width: 400px) {
    width: 250px;
  
`;
const Label = styled.label`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 0px 5px;
  padding: 10px 0px 0px 10px; 
  color: #333c4a;

`;
const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #076633;
  border-radius: 10px;
  color: #fff;
  padding: 0px 30px;
  border: none;
  height: 37px;
  width:150px;
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    width: 250px;
    height: 47px;

  @media (max-width: 400px) {
    width: 250px;
    height: 47px;
`;

const Grid = ({ urlLogin, usuarioLogado, usuariosCadastrados, urlApi }) => {

  const tipo = localStorage.getItem('tipo');


  const handleGerenciarUsuario = () => {
    const url = `/gerenciar-usuarios/`;
    window.location.href = url;
  };




  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('cpf');
    localStorage.removeItem('tipo');
    localStorage.removeItem('cod_fornecedor');
    localStorage.removeItem('desc_fornecedor_selecionado');

    const url = `${urlLogin}`;
    window.location.href = url;
  };


  //-------------------------------------------------------------//
  const codFornecedor = localStorage.getItem('cod_fornecedor');
  const descFornecedorSelecionado = localStorage.getItem('desc_fornecedor_selecionado');


  const [mostraUsuarioSelecionado, setMostraUsuarioSelecionado] = useState(false);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const handleSelecionar = () => {
    if (usuarioSelecionado) {
      // Divide o valor selecionado em cod_fornecedor e desc_fornecedor
      const [codFornecedor, descFornecedorSelecionado] = usuarioSelecionado.split(';');

      // Remove valores antigos
      localStorage.removeItem('cod_fornecedor');
      localStorage.removeItem('desc_fornecedor_selecionado');

      // Salva os novos valores no localStorage
      localStorage.setItem('cod_fornecedor', codFornecedor);
      localStorage.setItem('desc_fornecedor_selecionado', descFornecedorSelecionado);
      setMostraUsuarioSelecionado(true);
    } else {
      console.warn('Nenhum usuário foi selecionado.');
    }
  };

  const handleLimpar = () => {
    // Remove os valores do localStorage

    localStorage.removeItem('desc_fornecedor_selecionado');
    // Atualiza o estado para esconder a área de cooperado selecionado
    const codFornecedor = 'admin';
    localStorage.setItem('cod_fornecedor', codFornecedor);
    setMostraUsuarioSelecionado(false);
    window.location.reload();
  };




  const handleAreaDeProducao = () => {
    if (codFornecedor === 'admin') {
      return toast.warn("Escolha um cooperado.")
    }
    window.location.href = '/area-de-producao/';
  }


  return (
    <>

      <Container>
        <H3 className="name-apresentacao">
          Olá <strong>{usuarioLogado.length > 0 ? usuarioLogado[0].Desc_Fornecedor : "Admin"}</strong>,
        </H3>
        <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair"}>
          <ImExit />
        </ButtonSide1>


        <BackgroundImage src={logo} style={{ width: '300px', height: '70px', padding: '0px 20px 20px 0px' }} alt="Logo" />
        {tipo === 'A' && (
          <>
            {/* Exibe o Select se não houver valores no localStorage ou `mostraUsuarioSelecionado` for false */}
            {(!mostraUsuarioSelecionado && !(codFornecedor && descFornecedorSelecionado)) && (
              <ContentWrapper>
                <InputArea1>
                  <Label>Selecione um cooperado:</Label>
                  <Div>
                    <Select onChange={(e) => setUsuarioSelecionado(e.target.value)}>
                      <option value="">Escolha</option>
                      {usuariosCadastrados && usuariosCadastrados.map((user) => (
                        <option key={user.CPF} value={`${user.COD_FORNECEDOR};${user.DESC_FORNECEDOR}`}>
                          {user.COD_FORNECEDOR} - {user.DESC_FORNECEDOR}
                        </option>
                      ))}
                    </Select>
                    <Button onClick={handleSelecionar}>
                      Selecionar
                    </Button>
                  </Div>
                </InputArea1>
              </ContentWrapper>
            )}

            {/* Exibe o rótulo do cooperado selecionado se os valores existirem */}
            {(mostraUsuarioSelecionado || (codFornecedor && descFornecedorSelecionado)) && (
              <ContentWrapper>
                <InputArea1>
                  <Label>Cooperado selecionado:</Label>
                  <Label>{codFornecedor} - {descFornecedorSelecionado}</Label>
                </InputArea1>
                <Button onClick={handleLimpar}>
                  Limpar
                </Button>
              </ContentWrapper>
            )}

          </>
        )}

        <ContentWrapper>

          {tipo === 'A' && (
            <Button2 onClick={handleGerenciarUsuario}>Gerenciar Usuários</Button2>
          )}

          <Button2 onClick={handleAreaDeProducao}>Área de Produção</Button2>


        </ContentWrapper>
        <ContentWrapper>

          <Cotacao urlApi={urlApi} />

        </ContentWrapper>


      </Container >
    </>

  );
};

export default Grid;
