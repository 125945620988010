import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 500px;

  @media (max-width: 480px) {
    width: 325px;
  }

  @media (max-width: 400px) {
    width: 270px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
  background: #fff;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SelectWithCheckboxes = ({ fazendaPorFornecedor, setFazendaEscolhida }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // Controla a abertura do dropdown

  const handleCheckboxChange = (value) => {
    setSelectedValues((prevSelected) => {
      const newSelected = prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value];
      setFazendaEscolhida(newSelected); // Atualiza estado principal
      return newSelected;
    });
  };

  return (
    <Wrapper>
      {/* Botão de abertura */}
      <Button type="button" onClick={() => setIsOpen(!isOpen)}>
        {selectedValues.length > 0
          ? `Selecionado(s): ${selectedValues}`
          : "Selecione as propriedades"}
      </Button>

      {/* Dropdown */}
      {isOpen && (
        <Dropdown>
          <List>
            <ListItem>
              <Label>
                <input
                  type="checkbox"
                  value=""
                  checked={selectedValues.length === 0}
                  onChange={() => setSelectedValues([])}
                />
              </Label>
            </ListItem>
            {fazendaPorFornecedor &&
              fazendaPorFornecedor.map((faz) => (
                <ListItem key={faz.COD_FAZENDA}>
                  <Label>
                    <input
                      type="checkbox"
                      value={faz.COD_FAZENDA}
                      checked={selectedValues.includes(faz.COD_FAZENDA)}
                      onChange={() => handleCheckboxChange(faz.COD_FAZENDA)}
                    />
                    {`${faz.COD_FAZENDA} - ${faz.DESCRICAO}`}
                  </Label>
                </ListItem>
              ))}
          </List>
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default SelectWithCheckboxes;
