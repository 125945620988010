import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import Swal from "sweetalert2";

const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 15px;
  border: 1px solid #e9ebec;
  margin: 10px; 0px 10px 0px;
`;
const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #e9ebec;
  border-radius: 10px;
  color: black;
  &:hover {
    background-color: #076633;
    color: #fff;
  }

  @media (max-width: 480px) {
    width: 15%;

  }

  @media (max-width: 400px) {
    width: 10%;
  }
`;
const Thead = styled.thead`
`;
const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;
const Tr = styled.tr`
  text-align: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: black;
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;
const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;
const Td3 = styled.td`
  width: 72%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  span{
    margin: 0px 5px;
  }

  @media (max-width: 480px) {
    width: 93%;
  @media (max-width: 400px) {
    width: 92%;
`;
const Input = styled.input`
  width: 300px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 37px;

  @media (max-width: 480px) {
    width: 200px;

  @media (max-width: 400px) {
    width: 150px;
  
`;
const Button = styled.button`
  cursor: pointer;
  border: none;
  color: black;
  background-color: #f5f5f5;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.3);
  }
`;
const Div = styled.div`
  display: flex;
  align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;

const Grid = ({ setOnEdit, urlApi, usuarios, setAtualiza  }) => {

  //Token da api
  const token = localStorage.getItem('token');



  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(usuarios);
  const [isLoading, setIsLoading] = useState(true);
  

  
  //PopUp de confirmação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja deletar o usuário?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleEdit = (item) => {
    setOnEdit(item);
  };


  // Deleta os usuários
  const handleDelete = async (item) => {
    const userConfirmed = await showPopUp();
    console.log(isLoading);
    if (!userConfirmed) {
      return;
    }

    try {
      // Tenta realizar a requisição para deletar o usuário
      await axios.delete(`${urlApi}/usuario/deletar-usuario/del-usuario/${item.CPF}`, {
        headers: { Authorization: `Bearer ${token}` },
      });


      toast.success("Usuário deletado com sucesso.");

      setAtualiza(true);

    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão

    }

  };





  useEffect(() => {
    //Input de pesquisa
    const handleSearch = async (term) => {
      setIsLoading(true);

      try {
        const filtered = usuarios.filter((user) => {
          const descricao = user.DESC_FORNECEDOR ? user.DESC_FORNECEDOR.toLowerCase() : '';
          //const cpf = user.CPF ? user.CPF.toLowerCase() : '';


          return (
            descricao.includes(term.toLowerCase()) //||
            //cpf.includes(term.toLowerCase()) 

          );
        });
        setFilteredUsers(filtered);
      } catch (error) {
        console.error("Erro ao pesquisar:", error);
      }

      setIsLoading(false);
    };

    handleSearch(searchTerm);
  }, [searchTerm, usuarios]);


  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };


  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  return (

    <>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: nome"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Div>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <ImArrowLeft size={20} />
          </Button>
          <span><strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ImArrowRight size={20} />
          </Button>
        </Div>
      </Td3>
      <Table >
        <Thead>

          <Tr>

            <Th>Descrição Fornecedor:</Th>
            <Th hideOnMobile>Código Fornecedor:</Th>
            <Th hideOnMobile>Tipo:</Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((item, i) => (
            <Tr key={i}>
              <Td width="auto">{item.DESC_FORNECEDOR}</Td>
              <Td width="auto" hideOnMobile>{item.COD_FORNECEDOR}</Td>
              <Td width="auto" hideOnMobile>{item.TIPO}</Td>

              <TdButton width="5%" onClick={() => handleEdit(item)}>
                Editar
              </TdButton>
              <TdButton width="5%" onClick={() => handleDelete(item)}>
                Excluir
              </TdButton>
            </Tr>
          ))}
        </Tbody>

      </Table>

    </>
  );
};


export default Grid;
