import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";

import Swal from "sweetalert2";

const DivContainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const FormContainer = styled.form`

  background-color: #e9ebec;
  padding: 20px 20px;
  border-radius: 15px;
  margin: 0 auto; 
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
const FormContainer1 = styled.form`
  width: 76.5%;
  padding: 10px 10px;
  border-radius: 15px;
  margin: 0 auto; 
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media (max-width: 400px) {
    width: 100%;
`;

const DivGeral = styled.form`
  background-color: #fff;
  flex-direction: row;
  background-color: #e9ebec;
`;

const Div = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;

`;
const Form1 = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
  
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: flex-end;
  background-color: #e9ebec;
  gap: 10px;
  border-radius: 15px;
  justify-content: start;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;
`;
const InputArea1 = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;


  
`;
const Input = styled.input`
  width: 300px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 37px;

  @media (max-width: 480px) {
    width: 200px;

  @media (max-width: 400px) {
    width: 190px;
  
`;
const Input1 = styled.input`
  width: 150px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 37px;

  @media (max-width: 480px) {
    width: 200px;

  @media (max-width: 400px) {
    width: 190px;
  
`;
const Label = styled.label`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 
  color:#333c4a;

`;



const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #076633;
  border-radius: 3px;
  color: #fff;
  padding: 0px 30px;
  border: none;
  height: 37px;
  width:100px;
  &:hover {
    transform: scale(1.1);
  }


`;

const Button2 = styled.button`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #076633;
  border-radius: 3px;
  color: #fff;
  padding: 0px 30px;
  border: none;
  height: 37px;
  width:100px;
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    width: 220px;

  @media (max-width: 400px) {
    width: 210px;
  
`;

const Div2 = styled.td`
  display: flex;
  flex-direction:column;
  align-items: center;
  background-color: #e9ebec;
`;

export const Tr = styled.tr`
  text-align: center;
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  select {
    margin-right: 5px;
  }
 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

`;

const Form = ({ urlApi, onEdit, setAtualiza }) => {

  const ref = useRef(null);

  // tokens
  const token = localStorage.getItem('token');

  const [usuarioConsultado, setUsuarioConsultado] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [loading, setLoading] = useState(false);


  const validateCPF = (cpf) => {
    // Implementação básica de validação de CPF
    const regex = /^\d{11}$/; // CPF somente números
    return regex.test(cpf);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!validateCPF(inputValue)) {
      toast.error("CPF inválido. Certifique-se de que contém 11 números.");
      return;
    }

    setLoading(true);


    try {

      const response = await axios.get(`${urlApi}/usuario/encontrar-usuario/get-usuario`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { cpfUsuario: inputValue }
      });

      if (response.data && response.data.length > 0) {
        console.log(response.data)
        setUsuarioConsultado(response.data);
      } else {
        setUsuarioConsultado([]);

      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
      setUsuarioConsultado([]);
      toast.error("Erro ao buscar usuário. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar a ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const [codFornecedor, setCodFornecedor] = useState('');
  const [nomeFornecedor, setNomeFornecedor] = useState('');
  const [senha, setSenha] = useState(null);
  const [repetirSenha, setRepetirSenha] = useState(null);

  useEffect(() => {
    if (onEdit) {
      // Transforma onEdit no formato esperado
      const formattedOnEdit = {
        cpf: onEdit.CPF,
        Cod_Fornecedor: onEdit.COD_FORNECEDOR,
        Desc_Fornecedor: onEdit.DESC_FORNECEDOR,
        TIPO: onEdit.TIPO, // Adicionado se for necessário
      };
      setInputValue(onEdit.CPF)
      // Define o estado com o formato atualizado
      setUsuarioConsultado([formattedOnEdit]);
    }
  }, [onEdit]);


  // Atualiza os valores com os dados de `usuarioConsultado`
  useEffect(() => {
    if (usuarioConsultado && usuarioConsultado[0]) {
      setCodFornecedor(usuarioConsultado[0]?.Cod_Fornecedor || "");
      setNomeFornecedor(usuarioConsultado[0]?.Desc_Fornecedor || "");
    }
  }, [usuarioConsultado]);


  const handleSubmit = async (e) => {
    e.preventDefault();


    // Validação de campos obrigatórios
    if (!inputValue || !senha || !repetirSenha || !usuarioConsultado[0]?.Cod_Fornecedor) {
      return toast.warn("Preencha todos os campos!");
    }


    // Verificação de correspondência das senhas
    if (senha !== repetirSenha) {
      return toast.error("As senhas não coincidem!");
    }

    // Confirmação do usuário
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    try {

      if (onEdit) {
        const cpf = inputValue;
        await axios
          .put(`${urlApi}/usuario/alterar-usuario/up-usuario/${cpf}`, {
            senha,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            toast.success(data);
            setInputValue('');
            setSenha('');
            setRepetirSenha('');
            setCodFornecedor('');
            setNomeFornecedor('');
            setUsuarioConsultado([]);
            setAtualiza(true);
          })
          .catch(async (error) => {
            const errorMessage = error.response?.data.error || 'Erro desconhecido';
            console.error("Error:", errorMessage);
            toast.error("Erro ao editar: " + errorMessage);

          });
      } else {
        const response = await axios.post(
          `${urlApi}/usuario/adicionar-usuario/add-usuario`,
          {
            cpf: inputValue,
            senha,
            cod_fornecedor: usuarioConsultado[0]?.Cod_Fornecedor,
            tipo: 'U',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        toast.success(response.data);
        setInputValue('');
        setSenha('');
        setRepetirSenha('');
        setCodFornecedor('');
        setNomeFornecedor('');
        setUsuarioConsultado([]);
        setAtualiza(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data.error || "Erro desconhecido";

      if (error.response?.data.error === "Usuário já existe.") {
        toast.error("Esse CPF ou código do fornecedor já está cadastrado.");
      } else {
        toast.error("Erro ao adicionar: " + errorMessage);
      }
    }

  };





  const handleLimpar = () => {
    setInputValue('');
    setSenha('');
    setRepetirSenha('');
    setCodFornecedor('');
    setNomeFornecedor('');
    setUsuarioConsultado([]);
  };

  return (
    <DivContainer>
      <FormContainer1 >
        <InputArea>
          <Input
            type="text"
            placeholder="Digite o CPF, apenas números."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.replace(/\D/g, ''))} // Remove caracteres não numéricos
          />
          <Button onClick={handleSearch} disabled={loading}>
            {loading ? "Buscando..." : "Buscar"}
          </Button>
        </InputArea>
      </FormContainer1>
      {usuarioConsultado.length > 0 && (
        <FormContainer >
          <DivGeral >
            <Div>
              <Div2>


                <Form1 ref={ref}>

                  <InputArea1>
                    <Label>Código do Fornecedor</Label>
                    <Input1

                      type="text"
                      value={codFornecedor}
                      readOnly
                    />
                  </InputArea1>
                  <InputArea1>
                    <Label>Nome do Fornecedor</Label>
                    <Input
                      type="text"
                      value={nomeFornecedor}
                      readOnly
                    />
                  </InputArea1>
                  <InputArea1>
                    <Label>Senha</Label>
                    <Input1
                      name="senha"
                      type="password"
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                      placeholder="Digite sua senha"
                    />
                  </InputArea1>
                  <InputArea1>
                    <Label>Repetir Senha</Label>
                    <Input1
                      name="repetirSenha"
                      type="password"
                      value={repetirSenha}
                      onChange={(e) => setRepetirSenha(e.target.value)}
                      placeholder="Digite sua senha novamente"
                    />
                  </InputArea1>
                  <Button2 title="Salvar" type="button" onClick={handleSubmit}>
                    Salvar
                  </Button2>
                  <Button2 title="Limpar" type="button" onClick={handleLimpar}>
                    Limpar
                  </Button2>


                </Form1>


              </Div2>
            </Div>


          </DivGeral>



        </FormContainer >
      )}
    </DivContainer>
  );
};

export default Form;


