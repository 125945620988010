import React, {  useEffect, useState } from "react";
import axios from 'axios';



import GlobalStyle from "../../styles/global.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Form from './components/Form.js';
import { toast, ToastContainer } from "react-toastify";


const Geral = styled.div`
`;
const Div = styled.div`


`;


function App() {

  //----------------------------//
  //Ip's da api e do front
  //const urlFront = "http://192.168.100.103:3050";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/login";
  const urlFront = "";
  const urlApi = "/api";
  const urlLogin = "/login";
  //----------------------------//

  //Token da api
  const token = localStorage.getItem('token');
  const cpf = localStorage.getItem('cpf');
  const tipo = localStorage.getItem('tipo');






  
  const [usuarioLogado, setUsuarioLogado] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(loading)

  //Verifica o token do usuário
  useEffect(() => {

    const getToken = async () => {
      try {
        await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      } catch (error) {
        if (error.response && [400, 401, 403].includes(error.response.status)) {
          window.location.href = `${urlFront}/login`;
        } else {
          console.error("Erro ao obter token:", error);
        }
      }
    };
  
    getToken();

    const handleSearch = async (e) => {
      setLoading(true);
      try {
  
        const response = await axios.get(`${urlApi}/usuario/encontrar-usuario/get-usuario`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { cpfUsuario: cpf }
        });
  
        if (response.data && response.data.length > 0) {
          setUsuarioLogado(response.data);
        } else {
        }
      } catch (error) {
        console.error("Erro ao buscar usuário:", error);
      } finally {
        setLoading(false);
      }
    };

    handleSearch();

  }, [token, cpf]);


  //----------------------------//


  const [usuariosCadastrados, setUsuariosCadastrados] = useState([]);
  useEffect(() => {

    if (tipo === 'A') {
      const fetchUsuarios = async () => {
        try {
          const res = await axios.get(`${urlApi}/usuario/listar-usuarios/get-usuario`, {
            headers: { Authorization: `Bearer ${token}` }
          });
    
          setUsuariosCadastrados(res.data);
        } catch (error) {
          console.error('Erro ao buscar dados de usuarios:', error);
        }
      };

      fetchUsuarios();
    }
    

  }, [tipo, token]);


  //----------------------------//
  
  return (
    <>
      <Geral>
        <Div>
          <Form urlLogin={urlLogin} usuarioLogado={usuarioLogado} usuariosCadastrados={usuariosCadastrados} urlApi={urlApi}/>
        </Div>
        <GlobalStyle />
      </Geral>
      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
