import React from "react";
import styled from "styled-components";



const Table = styled.table`
  width: 98%;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 15px;
  border: 1px solid #e9ebec;
  margin: 10px 10px;

  @media (max-width: 480px) {
    display: block; /* Para permitir layout de cards no mobile */
  }

`;



const Thead = styled.thead`
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;

const Tbody = styled.tbody`
  padding-bottom: 25px; 
  @media (max-width: 480px) {
    display: block; /* Torna cada linha um card */
  }
`;

const Tr = styled.tr`
  text-align: center;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 480px) {
    display: flex; /* Cada linha da tabela vira um card */
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: black;
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
  
  @media (max-width: 480px) {
    display: none; /* Oculta cabeçalhos no layout de cards */
  }
`;

const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
  
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 14px;

    &::before {
      content: attr(data-label); /* Exibe o cabeçalho como rótulo no layout de cards */
      font-weight: bold;
      margin-right: 10px;
    }
  }
`;



const Grid = ({ relatorioColheitaProducaoTotalPorFazenda, modalDetalheFazenda }) => {



  //Abre o alterar nome lista
  const handleGrid = (item) => {
    modalDetalheFazenda(item)
  };


  return (
    <>


      <h2>Relatório Detalhado</h2>  
      {relatorioColheitaProducaoTotalPorFazenda && (
        <Table>
          <Thead hideOnMobile>
            <Tr>
              <Th>Cód. Fazenda:</Th>
              <Th>Área:</Th>
              <Th>Carreg. Qtdade.:</Th>
              <Th>Carreg. Médio:</Th>
              <Th>Raio Médio:</Th>
              <Th>Hrs. Pós-Queima:</Th>
              <Th>Peso Bruto:</Th>
              <Th>Peso Tara:</Th>
              <Th>Peso Líquido:</Th>
              <Th>TCH:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {relatorioColheitaProducaoTotalPorFazenda.map((item, i) => (
              <Tr key={i} onClick={() => handleGrid(item)}>
                <Td data-label="Cód. Fazenda">{item.COD_FAZENDA}</Td>
                <Td data-label="Área">{item.AREA}</Td>
                <Td data-label="Carreg. Qtdade.">{item.NR_CARREGAMENTO}</Td>
                <Td data-label="Carreg. Médio">{item.MEDIA_VIAGEM}</Td>
                <Td data-label="Raio Médio">{item.RAIOMEDIO}</Td>
                <Td data-label="Hrs. Pós-Queima">{item.MHPQ}</Td>
                <Td data-label="Peso Bruto">{item.PESOBRUTO}</Td>
                <Td data-label="Peso Tara">{item.PESOTARA}</Td>
                <Td data-label="Peso Líquido">{item.PESOLIQUIDO}</Td>
                <Td data-label="TCH">{item.TCH}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}





    </>
  );
};


export default Grid;
