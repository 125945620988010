import React, { useState, useEffect } from 'react';

import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





const FormContainer = styled.form`
  width: 100%;
  max-width: 440px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex; 
  justify-content: center; 
  align-items: center;
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0px 0px 10px 10px;
  gap: 5px;
  flex-direction: column;
  margin: auto;

  @media (max-width: 1360px) {
    max-width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 1024px) {
    width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 380px;
    justify-content: space-between; 
  }
  @media (max-width: 480px) {

    width: 340px;
    justify-content: space-between; 
  }
  @media (max-width: 376px) {
    width: 320px;
  }
`;

const Title2 = styled.div`
  padding-top: 2vw;
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;

  div {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center;    /* Centraliza verticalmente */
    width: 100%;            /* Garante que ocupe toda a largura */
    height: 100%;           /* Garante que ocupe toda a altura */
  }

  @media (max-width: 480px) {
    width: 100%;            /* Ocupa toda a largura da tela */
  }
`;

const Title = styled.h1`
  font-size: 34px;
  color: #3d4244;
  margin-bottom: 5px;
  font-family: "Arial", sans-serif;

  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 480px) {
    font-size: 32px;       /* Reduz o tamanho da fonte */
    margin: 0;            /* Remove a margem */
    text-align: center;   /* Centraliza o texto */
  }

  @media (max-width: 400px) {
    font-size: 30px;       /* Reduz mais o tamanho da fonte */
    margin: 0;            /* Remove a margem */
    text-align: center;   /* Centraliza o texto */
  }
`;

const Card = styled.div`
  width: 360px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  text-align: center;

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const Input = styled.input`
  font-family: "Open Sans", sans-serif;
  width: 94%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #076633;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #a5adb0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #076633;
  }
`;

const ForgotPassword = styled.p`
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #076633;
  }
`;



function Login({ urlApi, modalNovaSenha }) {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);



  const handleLogin = async () => {
    if (!senha || !cpf) {
      toast.error('Por favor, preencha todos os campos.');
      return;
    }


    setIsSubmitting(true); // Bloqueia múltiplos cliques

    try {

      const response = await fetch(`${urlApi}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: cpf,
          senha,
        }),
      });



      const data = await response.json();
      const tokenUsuario = data.token;
      const cpfStorange = data.cpf;
      const tipo = data.tipo;
      const cod_fornecedor = data.cod_fornecedor;


      if (response.ok) {
        localStorage.setItem('token', tokenUsuario);
        localStorage.setItem('cpf', cpfStorange);
        localStorage.setItem('tipo', tipo);
        localStorage.setItem('cod_fornecedor', cod_fornecedor !== null ? cod_fornecedor : 'admin');



        toast.success(data.mensagem);

        // Redefinir o formulário
        const form = document.querySelector('form');
        if (form) {
          form.reset();
        }
        
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);

      } else {
        toast.error(data.error || 'Erro desconhecido no login.');


      }
    } catch (error) {
      alert('Erro de rede ou erro desconhecido.');
      console.log(error)

    } finally {
      setIsSubmitting(false); // Reativa o botão
    }
  };






  // Chamar a função com enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Previne o comportamento padrão do Enter (submeter o formulário)
      handleLogin();
    }
  };



  const novaSenha = () => {
    modalNovaSenha();
  };


  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('cpf');
    localStorage.removeItem('tipo');
    localStorage.removeItem('cod_fornecedor');
    localStorage.removeItem('desc_fornecedor_selecionado');
  }, []);

  return (
    <FormContainer onKeyDown={handleKeyDown}>
      <Title2>

          <Title>Portal do Cooperado</Title>

      </Title2>

      <Card>
        <form>
          <Input
            type="text"
            placeholder="Digite seu CPF"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Digite sua senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <Button type="button" onClick={handleLogin} disabled={isSubmitting}>
            {isSubmitting ? "Entrando..." : "Entrar"}
          </Button>
        </form>
        <ForgotPassword onClick={novaSenha}>Esqueci minha senha</ForgotPassword>
      </Card>
    </FormContainer>
  );
}

export default Login;

