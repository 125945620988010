import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import { toast } from "react-toastify";

const Div = styled.div`
  width: 90vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ced2d4;
  border-radius: 15px;
  border: 1px solid #e9ebec;
  margin: 10px 10px;
`;
const Table = styled.table`
  width: 98%;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 15px;
  border: 1px solid #e9ebec;
  margin: 10px 10px;

  @media (max-width: 480px) {
    display: block; /* Para permitir layout de cards no mobile */
  }

`;
const Thead = styled.thead`
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;
const Tbody = styled.tbody`
  padding-bottom: 25px; 
  @media (max-width: 480px) {
    display: block; /* Torna cada linha um card */
  }
`;
const Tr = styled.tr`
  text-align: center;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 480px) {
    display: flex; /* Cada linha da tabela vira um card */
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: black;
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
  
  @media (max-width: 480px) {
    display: none; /* Oculta cabeçalhos no layout de cards */
  }
`;
const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};


  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
  
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 14px;

    &::before {
      content: attr(data-label); /* Exibe o cabeçalho como rótulo no layout de cards */
      font-weight: bold;
      margin-right: 10px;
    }
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;
const H3 = styled.h3`
  font-size: 24px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Grid = ({ urlApi, detalheFazenda, dataInicio, dataFim, modalDetalheFazenda, relatorioColheitaProducaoTotalPorFazenda }) => {
  const codFornecedor = localStorage.getItem('cod_fornecedor');
  const token = localStorage.getItem('token');

  const [loading, setLoading] = useState(false);
  const dataFormatada1 = moment(dataInicio).format("DD/MM/YYYY");
  const dataFormatada2 = moment(dataFim).format("DD/MM/YYYY");

  const [relatorioColheitaProducaoParaFazenda, setRelatorioColheitaProducaoParaFazenda] = useState([]);


  const totalizador = relatorioColheitaProducaoTotalPorFazenda.filter((faz) =>
    relatorioColheitaProducaoParaFazenda.some(
      (fazenda) => fazenda.COD_FAZENDA === faz.COD_FAZENDA
    )
  );



  useEffect(() => {
    const handleSearch = async (e) => {


      if (!dataInicio || !dataFim) {
        return toast.error("A data inicio e a data fim não podem estar vazias!");
      }

      if (dataInicio > dataFim) {
        return toast.error("A data inicio não pode ser maior que a data fim!");
      }


      setLoading(true);
      try {
        const response = await axios.get(`${urlApi}/area-de-producao/colheita-producao-para-fazenda/`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            codFornecedor: codFornecedor,
            dataInicio: dataFormatada1,
            dataFim: dataFormatada2,
            fazenda: detalheFazenda.COD_FAZENDA,
          },
        });
        setRelatorioColheitaProducaoParaFazenda(response.data);
        console.log(loading);



      } catch (error) {


        if (error.response && error.response.status === 404) {
          // Mensagem personalizada para 404
          setRelatorioColheitaProducaoParaFazenda([]);

          toast.error(error.response.data.message || "Nenhum dado encontrado.");
        } else {
          // Mensagem genérica para outros erros
          toast.error("Erro ao buscar dados. Tente novamente.");
        }
      } finally {
        setLoading(false);
      }
    };

    handleSearch();
  }, [modalDetalheFazenda, detalheFazenda]);


  return (
    <>

      {Object.entries(
        relatorioColheitaProducaoParaFazenda.reduce((acc, item) => {
          // Agrupa os itens por COD_FAZENDA
          if (!acc[item.COD_FAZENDA]) {
            acc[item.COD_FAZENDA] = [];
          }
          acc[item.COD_FAZENDA].push(item);
          return acc;
        }, {})
      ).map(([fazenda, items]) => (
        <Div key={fazenda}>

          <H3>{fazenda} - {items[0]?.DESCRICAO_FAZENDA || "Descrição não disponível"}  </H3>
          <H3>Tipo de corte: {items[0]?.DESC_CORTE || "Descrição não disponível"} </H3>
          <Table>
            <Thead hideOnMobile>
              <Tr >
                <Th >Data:</Th>

                <Th >Carreg. Qtdade.:</Th>
                <Th >Carreg. Médio:</Th>
                <Th >Raio Médio:</Th>
                <Th >Hrs. Pós-Queima:</Th>
                <Th >Peso Bruto:</Th>
                <Th >Peso Tara:</Th>
                <Th >Peso Líquido:</Th>
                <Th >TCH:</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item, i) => (
                <Tr key={i}>
                  <Td data-label="Data" width="auto">{new Date(item.DATA).toLocaleDateString('pt-BR')}</Td>

                  <Td data-label="Carreg. Qtdade." width="auto">{item.NR_CARREGAMENTO}</Td>
                  <Td data-label="Carreg. Médio" width="auto">{item.MEDIA_VIAGEM}</Td>
                  <Td data-label="Raio Médio" width="auto">{item.RAIOMEDIO}</Td>
                  <Td data-label="Hrs. Pós-Queima" width="auto">{item.MHPQ}</Td>
                  <Td data-label="Peso Bruto" width="auto">{item.PESOBRUTO}</Td>
                  <Td data-label="Peso Tara" width="auto">{item.PESOTARA}</Td>
                  <Td data-label="Peso Líquido" width="auto">{item.PESOLIQUIDO}</Td>
                  <Td data-label="TCH" width="auto">{item.TCH}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Div>
      ))}
      <Div>
        <Table>
          <Thead hideOnMobile>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th>Carreg. Qtdade.:</Th>
              <Th>Carreg. Médio:</Th>
              <Th>Raio Médio:</Th>
              <Th>Hrs. Pós-Queima:</Th>
              <Th>Peso Bruto:</Th>
              <Th>Peso Tara:</Th>
              <Th>Peso Líquido:</Th>
              <Th>TCH:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {totalizador.map((item, i) => (
              <Tr key={i}>
                <Td width="auto"><strong>Total {item.COD_FAZENDA}:</strong></Td>
                <Td width="auto"></Td>
                <Td data-label="Carreg. Qtdade." width="auto"><strong>{item.NR_CARREGAMENTO}</strong></Td>
                <Td data-label="Carreg. Médio" width="auto"><strong>{item.MEDIA_VIAGEM}</strong></Td>
                <Td data-label="Raio Médio" width="auto"><strong>{item.RAIOMEDIO}</strong></Td>
                <Td data-label="Hrs. Pós-Queima" width="auto"><strong>{item.MHPQ}</strong></Td>
                <Td data-label="Peso Bruto" width="auto"><strong>{item.PESOBRUTO}</strong></Td>
                <Td data-label="Peso Tara" width="auto"><strong>{item.PESOTARA}</strong></Td>
                <Td data-label="Peso Líquido" width="auto"><strong>{item.PESOLIQUIDO}</strong></Td>
                <Td data-label="TCH" width="auto"><strong>{item.TCH}</strong></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Div>
    </>
  );
};


export default Grid;
