import React, { useState, useEffect } from 'react';
import axios from 'axios';

import styled from "styled-components";




const Div = styled.div`
  width: 98%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color:  #3d4244;
  border: 1px solid #ced2d4; 
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  margin: 10px 10px;
`;

const Table = styled.table`
  width: 98%;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 15px;
  border: 1px solid #e9ebec;
  margin: 10px 10px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

const Tr = styled.tr`
  text-align: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: black;
  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;

const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  @media (max-width: 480px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }

  @media (max-width: 400px) {
    ${(props) => props.hideOnMobile && "display: none;"}
  }
`;


const B3Quotes = ({ urlApi }) => {
  const [loading, setLoading] = useState(true);
  //-----------------------------------------------------------------//
  const [quotes01, setQuotes01] = useState([]);


  const fetchQuotes01FromBackend = async () => {
    try {
      const response = await axios.get(`${urlApi}/cotacao/ETH`);
      setQuotes01(response.data.quotes);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar cotações do backend:', error.message);
      setLoading(false);
    }
  };


  //-----------------------------------------------------------------//
  const [quotes02, setQuotes02] = useState([]);


  const fetchQuotes02FromBackend = async () => {
    try {
      const response = await axios.get(`${urlApi}/cotacao/CCM`);
      setQuotes02(response.data.quotes);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar cotações do backend:', error.message);
      setLoading(false);
    }
  };


  //-----------------------------------------------------------------//

  // Busca as cotações ao montar o componente
  useEffect(() => {
    fetchQuotes01FromBackend();
    fetchQuotes02FromBackend();

    // Configura os intervalos para cada função
    const interval01 = setInterval(fetchQuotes01FromBackend, 3600000); // Atualiza a cada 1 hora
    const interval02 = setInterval(fetchQuotes02FromBackend, 3600000); // Atualiza a cada 1 hora

    // Limpa ambos os intervalos ao desmontar o componente
    return () => {
      clearInterval(interval01);
      clearInterval(interval02);
    };
  }, []);




  


  if (loading) {
    return <p>Carregando cotações...</p>;
  }

  return (
    <Div>
      <h1>Cotações B3</h1>
      <Table>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Ativo</Th>
            <Th>Preço</Th>
            <Th>(%)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {quotes01.map((quote) => (
            <Tr key={quote.symbol}>
              <Td>Etanol Hidratado</Td>
              <Td>{quote.symbol}</Td>
              <Td>R$ {quote.regularMarketPrice.toFixed(2)}</Td>
              <Td>{quote.regularMarketChangePercent.toFixed(2)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Table>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Ativo</Th>
            <Th>Preço</Th>
            <Th>(%)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {quotes02.map((quote) => (
            <Tr key={quote.symbol}>
              <Td>Milho</Td>
              <Td>{quote.symbol}</Td>
              <Td>R$ {quote.regularMarketPrice.toFixed(2)}</Td>
              <Td>{quote.regularMarketChangePercent.toFixed(2)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Div>
  );
};

export default B3Quotes;
