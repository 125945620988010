import React, { useState, useEffect } from "react";
import axios from "axios";

import Form from "./components/Form.js";
import Grid from "./components/Grid.js";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import GlobalStyle from "../../styles/global.js";
import { FaArrowLeft } from "react-icons/fa";

const Div2 = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #1e242c;
  display: flex;
  flex-direction: column;
  align-items: center;

`;
const Container = styled.div`
  width: 98vw;
  
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  border-radius: 15px;
  background-color:#f7f7f8;
  margin-top: 15px;
`;
const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 100%;
  align-items: center;
  h2{
    color:black;
    margin-left: 5%;  
    
    @media (max-width: 480px) {
    font-size: 32px;  
    margin-left: 7%;

    @media (max-width: 400px) {
    font-size: 30px;  
    margin-left: 7%;     
    
  }
  }
`;
const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: auto;
 
`;
const Title2 = styled.h2`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
  color:black;
  font-size: 28px;

`;
const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  color: #333c4a;
  font-size: 18px;
  background-color: #f7f7f8;
  &.toggle-btn {
    margin-left: 1vw;
  }

  &.button-exit{
    margin-right: 1vw;
  }

`;

function Login() {

  const token = localStorage.getItem('token');
  const tipo = localStorage.getItem('tipo');


  const [onEdit, setOnEdit] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [atualiza, setAtualiza] = useState(false);
  
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlFront = "http://192.168.100.103:3050/";
  //const urlLogin = "http://192.168.100.103:3050/Login";
  const urlApi = "/api";
  const urlFront = "/";
  const urlLogin = "/login";


  useEffect(() => {
    const getToken = async () => {
      try {
        await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      } catch (error) {
        if (error.response && [400, 401, 403].includes(error.response.status)) {
          window.location.href = `${urlFront}login`;
        } else {
          console.error("Erro ao obter token:", error);
        }
      }
    };
  
    getToken();
  }, [token, onEdit, usuarios, atualiza]);











  const handleVoltar = () => {
    const url = `${urlFront}`;
    window.location.href = url;
  };






  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const res = await axios.get(`${urlApi}/usuario/listar-usuarios/get-usuario`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        setUsuarios(res.data);
        setAtualiza(false);
      } catch (error) {
        console.error('Erro ao buscar dados de usuarios:', error);
      }
    };

    fetchUsuarios();
    if (tipo === 'U') {
      const url = `${urlFront}`;
      window.location.href = url;
    }

  }, [tipo, token, atualiza]);





  return (
    <Div2>
      <Container>
        <Title>

          <Title2>

            <Div>
              <ButtonSide1 className="toggle-btn" onClick={handleVoltar}>
                <FaArrowLeft className="gap" size={20}/>
              </ButtonSide1>
              <Div>
                <h2>Gerenciar Usuários</h2>
              </Div>

             
            </Div>

          </Title2>



        </Title>



        <Form urlApi={urlApi} onEdit={onEdit} setAtualiza={setAtualiza}/>


      </Container>
      <Container>

        <Grid usuarios={usuarios} urlApi={urlApi} setAtualiza={setAtualiza} setOnEdit={setOnEdit} />

      </Container>
      <ToastContainer
        autoClose={1500}
        position={toast.POSITION.BOTTOM_LEFT}
        style={{ width: '400px', fontSize: '22px' }}
      />
      <GlobalStyle />
    </Div2>
  );
}

export default Login;
