import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DashboardPortal from './components/portal/Dashboard.js';

import Login from './components/login/Login.js';
import CadastroUsuario from './components/gerenciar-usuarios/Gerenciar-usuarios.js';

import AreaDeProducao from './components/area-de-producao/Area-de-producao.js'

function App() {
    return (
        <Router>

            <Routes>
                <Route path="/login"   element={<Login />}  />

                <Route exact path="/"   element={<DashboardPortal />} />

                <Route path="/gerenciar-usuarios"   element={<CadastroUsuario />} />
                
                <Route path="/area-de-producao"   element={<AreaDeProducao />} />
            </Routes>
        </Router>

    )
}
export default App;

