import React, { useState, useEffect } from "react";
import axios from "axios";

import GridTotalGeral from "./components/GridTotalGeral.js";
import GridTotalPorFazenda from "./components/GridTotalPorFazenda.js";
import ModalDetalheFazenda from "./components/ModalDetalheFazenda.js";
import SelectWithCheckboxes from './components/Select.js';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import GlobalStyle from "../../styles/global.js";
import { FaArrowLeft } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import moment from "moment";

const Div2 = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ced2d4;
  display: flex;
  flex-direction: column;
  align-items: center;

`;
const Container = styled.div`
  width: 98vw;
  
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  border-radius: 15px;
  background-color:#f7f7f8;
  margin-top: 15px;
`;
const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 100%;
  align-items: center;
  h2{
    color:black;
    margin-left: 5%;  

    @media (max-width: 480px) {
    font-size: 32px;  
    margin-left: 7%;

    @media (max-width: 400px) {
    font-size: 30px;  
    margin-left: 7%;     
    
  }
  }
`;
const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: auto;
 
`;
const Title2 = styled.h2`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
  color:black;
  font-size: 28px;

`;
const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  color: #333c4a;
  font-size: 18px;
  background-color: #f7f7f8;
  &.toggle-btn {
    margin-left: 1vw;
  }

  &.button-exit{
    margin-right: 1vw;
  }

`;
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;
const ModalContent = styled.div`
  background-color: #515151;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 
  overflow-x: hidden; 
  

`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;
const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #515151;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;
const DivContainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
const FormContainer1 = styled.form`
  width: 76.5%;
  padding: 10px 10px;
  border-radius: 15px;
  margin: 0 auto; 
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
`;
const InputArea1 = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;


  
`;
const Input1 = styled.input`
  width: 150px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 37px;

  @media (max-width: 480px) {
    width: 300px;

  @media (max-width: 400px) {
    width: 250px;
  
`;
const Label = styled.label`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 
  color:#333c4a;

`;
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #076633;
  border-radius: 3px;
  color: #fff;
  padding: 0px 30px;
  border: none;
  height: 37px;
  width:100px;
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    width: 320px;
    height: 47px;

  @media (max-width: 400px) {
    width: 270px;
    height: 47px;
`;

function Login() {

  const token = localStorage.getItem('token');
  const codFornecedor = localStorage.getItem('cod_fornecedor');



  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlFront = "http://192.168.100.103:3050/";

  const urlApi = "/api";
  const urlFront = "/";

  //----------------------------------------------------------//
  // Verifica o Token
  useEffect(() => {
    const getToken = async () => {
      try {
        await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      } catch (error) {
        if (error.response && [400, 401, 403].includes(error.response.status)) {
          window.location.href = `${urlFront}login`;
        } else {
          console.error("Erro ao obter token:", error);
        }
      }
    };

    getToken();
  }, [token]);



  //----------------------------------------------------------//
  //Retorna as fazendas por codFornecedor
  const [loading, setLoading] = useState(false);

  const [fazendaPorFornecedor, setFazendaPorFornecedor] = useState(null);
  useEffect(() => {
    //Array que alimenta o select do form - Mostra as fazendas por codFornecedor
    const handleFazendaPorFornecedor = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${urlApi}/area-de-producao/fazenda-por-fornecedor/`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            codFornecedor: codFornecedor,
          },
        });
        setFazendaPorFornecedor(response.data);
        console.log(loading)
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    handleFazendaPorFornecedor();
  }, []);



  //----------------------------------------------------------//
  //Retorna os dados dos cooperados
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [fazendaEscolhida, setFazendaEscolhida] = useState(null);


  const dataFormatada1 = moment(dataInicio).format("DD/MM/YYYY");
  const dataFormatada2 = moment(dataFim).format("DD/MM/YYYY");

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!dataInicio || !dataFim) {
      return toast.error("A data inicio e a data fim não podem estar vazias!");
    }

    if (dataInicio > dataFim) {
      return toast.error("A data inicio não pode ser maior que a data fim!");
    }


    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/area-de-producao/colheita-producao/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          codFornecedor: codFornecedor,
          dataInicio: dataFormatada1,
          dataFim: dataFormatada2,
          fazenda: fazendaEscolhida,
        },
      });
      setRelatorioColheitaProducaoTotal(response.data);

      const response2 = await axios.get(`${urlApi}/area-de-producao/colheita-producao-por-fazenda/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          codFornecedor: codFornecedor,
          dataInicio: dataFormatada1,
          dataFim: dataFormatada2,
          fazenda: fazendaEscolhida,
        },
      });
      setRelatorioColheitaProducaoTotalPorFazenda(response2.data);

      setGridTotalPorProriedade(false);
    } catch (error) {


      if (error.response && error.response.status === 404) {
        // Mensagem personalizada para 404
        setRelatorioColheitaProducaoTotal([]);
        setRelatorioColheitaProducaoTotalPorFazenda([]);
        setGridTotalPorProriedade(false);
        toast.error(error.response.data.message || "Nenhum dado encontrado.");
      } else {
        // Mensagem genérica para outros erros
        toast.error("Erro ao buscar dados. Tente novamente.");
      }
    } finally {
      setLoading(false);
    }
  };

















  const handleVoltar = () => {
    const url = `${urlFront}`;
    window.location.href = url;
  };





  const [relatorioColheitaProducaoTotal, setRelatorioColheitaProducaoTotal] = useState([]);
  const [relatorioColheitaProducaoTotalPorFazenda, setRelatorioColheitaProducaoTotalPorFazenda] = useState([]);

  const [GridTotalPorProriedade, setGridTotalPorProriedade] = useState(false);


  const [detalheFazenda, setDetalheFazenda] = useState(false); //Recebe o valor da coluna, passa para o NovoCard criar o cartão
  const [isModalOpenDetalheFazenda, setIsModalOpenDetalheFazenda] = useState(false);
  const modalDetalheFazenda = (item) => {
    setIsModalOpenDetalheFazenda(!isModalOpenDetalheFazenda);
    setDetalheFazenda(item)
  };



  return (
    <>
      {isModalOpenDetalheFazenda && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalDetalheFazenda();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalDetalheFazenda urlApi={urlApi} detalheFazenda={detalheFazenda} dataInicio={dataInicio} dataFim={dataFim} modalDetalheFazenda={modalDetalheFazenda} relatorioColheitaProducaoTotalPorFazenda={relatorioColheitaProducaoTotalPorFazenda} />
          </ModalContent>
        </ModalContainer>
      )}

      <Div2>
        <Container>
          <Title>

            <Title2>

              <Div>
                <ButtonSide1 className="toggle-btn" onClick={handleVoltar}>
                  <FaArrowLeft className="gap" />
                </ButtonSide1>
                <Div>
                  <h2>Àrea de Produção</h2>
                </Div>


              </Div>

            </Title2>



          </Title>



          <DivContainer>
            <FormContainer1 >
              <InputArea1>
                <Label>Data inicio</Label>
                <Input1
                  type="date"
                  placeholder="Digite data inicio."
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)} // Remove caracteres não numéricos
                />
              </InputArea1>
              <InputArea1>

                <Label>Data fim</Label>
                <Input1
                  type="date"
                  placeholder="Digite a data fim."
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)} // Remove caracteres não numéricos
                />

              </InputArea1>
              <InputArea1>

                <Label>Propriedades</Label>
                
                <SelectWithCheckboxes
                  fazendaPorFornecedor={fazendaPorFornecedor}
                  setFazendaEscolhida={setFazendaEscolhida}
                  
                />
              </InputArea1>
              <InputArea1>

                <Button onClick={handleSearch} disabled={loading}>
                  {loading ? "Buscando..." : "Buscar"}
                </Button>
              </InputArea1>
            </FormContainer1>





          </DivContainer>


        </Container>

        <Container>
          {relatorioColheitaProducaoTotal?.length > 0 && (
            <GridTotalGeral urlApi={urlApi} relatorioColheitaProducaoTotal={relatorioColheitaProducaoTotal} setGridTotalPorProriedade={setGridTotalPorProriedade} />
          )}
          {GridTotalPorProriedade === true && (
            <GridTotalPorFazenda urlApi={urlApi} relatorioColheitaProducaoTotalPorFazenda={relatorioColheitaProducaoTotalPorFazenda} modalDetalheFazenda={modalDetalheFazenda} />
          )}



        </Container>
        <ToastContainer
          autoClose={1500}
          position={toast.POSITION.BOTTOM_LEFT}
          style={{ width: '400px', fontSize: '22px' }}
        />
        <GlobalStyle />
      </Div2>
    </>
  );


}

export default Login;
